import { Link, useLocation } from "@remix-run/react";
import { useEffect } from "react";
import VanillaTilt from "vanilla-tilt";

import { i18n } from "~/i18n.config";
import { fixLineHeight } from "~/utils/fixLineHeight";

import { useGlobalContext } from "../context/GlobalContext";

import styles from "./Tile.module.scss";

import type { Locale } from "~/i18n.config";

export default function Tile({
	title,
	slug,
	imageUrl,
}: {
	title: string;
	slug: string;
	imageUrl: string;
}): React.ReactNode {
	const { pathname } = useLocation();
	const { locale } = useGlobalContext();

	useEffect(() => {
		// this is extra annoying
		setTimeout(() => {
			fixLineHeight();
		}, 250);
		window.addEventListener("resize", fixLineHeight);

		const elements = document.querySelectorAll<HTMLElement>(".news-tile");

		// Tilt needs a proper react wrapper
		VanillaTilt.init(Array.from(elements), {
			max: 5,
			speed: 300,
			scale: 1.035,
			glare: false,
		});
	}, []);

	return (
		<div
			className={`news-tile column is-full-mobile is-half-tablet ${i18n.locales.includes(pathname.slice(1)[1] as Locale) ? "is-half-desktop" : "is-one-third-desktop"}`}
		>
			<Link to={`/${locale}/news/${slug}`}>
				<div className="card is-clickable">
					<div className="card-image">
						<figure className="image is-16by9">
							<img src={imageUrl} width={640} height={360} alt={`${title} main image`} />
						</figure>
					</div>
					<div className={`card-content p-3 ${styles.tileBorder}`}>
						<div className="content titlebar">
							<h3 className="title is-uppercase has-text-weight-bold my-0 is-size-5-mobile is-size-5-tablet is-size-5-desktop has-text-white">
								{title}
							</h3>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
}
